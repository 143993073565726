import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Dialog from '../Dialog';

import {
    createIdea,
    updateIdea,
    getMessagesByIssuesIds,
    createIssuesWithMessages,
} from '../../../api/contradictionsSolvings';
import {
    getGeneratedNamesForIdea,
    getGeneratedBenefits,
    getGeneratedChallenges,
    getGeneratedImage,
    getContradictionSolving,
} from '../../../api/ai';
import { uploadImage } from '../../../api/uploads';
import _ from 'lodash';
import WarningNotTokensModal from '../../ComponentsDashboard/WarningNotTokensModal';
import BuyMoreAICreditsModal from '../BuyMoreAICreditsModal';
import CheckoutModal from '../CheckoutModal';
import { setGeneratedOpenAIRequests } from '../../../actions/projectActions';

import EurekaModalHeader from './EurekaModalHeader';
import EurekaModalTabs from './EurekaModalTabs';
import EurekaModalFooter from './EurekaModalFooter';
import ErrorMessageModal from '../../../components/dialogs/ErrorMessageModal';
import { getTranslation } from '../../../helpers/getLanguage';
import './styles.css';

const IDEAS_IDENTIFICATION = {
    EMPTY_IDEA: 0,
    IN_PROGRESS_IDEA: 1,
    NOT_FEASIBLE_IDEA: 2,
    COMPLETED_IDEA: 3,
};

const initialState = {
    isIdeaFeasible: null,
    score: null,
    feasibleScore: null,
    potentialBenefitsOfContradictionSolving: [{ name: '', description: '', target: [], userInfo: '' }],
    issues: [{ comment: '', description: '', createdByUser: '' }],
    issuesMessages: [],
    description: '',
    name: '',
    image: { name: '', originalName: '', id: '' },
    imagesLibrary: [],
};

const initialPaymentInfo = {
    intervalCount: 1,
    quantity: 1,
    key: '',
    interval: null,
    openAIRequests: null,
    total: null,
    promoCode: '',
    promoCodeOpenAI: '',
};

const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
    uploadName: '',
};

const isDefaultCategory = (value) => ['Consumer', 'Company', 'Other'].includes(value);

const ContradictionEditModal = ({
    closeDialog,
    edit,
    projectId,
    project,
    projectName,
    contradiction,
    teamId,
    categories = [],
    productType,
    currentContradiction,
    productName,
    language,
    history,
    setGeneratedOpenAIRequests,
    contradictionId,
    currentContradictionSolvingType,
    contradictionName,
    ...props
}) => {
    const [Contradiction, setContradiction] = useState(_.cloneDeep(initialState));
    const [currentTab, setCurrentTab] = useState(0);
    const [isNew, setIsNew] = useState(!edit);
    const [ContradictionId, setContradictionId] = useState(contradiction.id);
    const [savedContradiction, setSavedContradiction] = useState(_.cloneDeep(initialState));
    const [isPending, setIsPending] = useState({ create: false, update: false });
    const [uploadInfo, setUploadInfo] = useState({ ...initialUpload });
    const [isClosing, setIsClosing] = useState(false);
    const [customCategories, setCustomCategories] = useState([...categories]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [isCompletedIdea, setIsCompletedIdea] = useState(false);
    const [messages, setMessages] = useState([]);
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: '',
    });
    const [suggestedOptions, setSuggestedOptions] = useState([]);
    const [selectedGenerateOptions, setSelectedGenerateOptions] = useState(new Set());
    const [openGenerateModal, setOpenGenerateModal] = useState(false);
    const [isOpenGenerateImageModal, setIsOpenGenerateImageModal] = useState(false);
    const [generatedImage, setGeneratedImage] = useState('');
    const [potentialGeneratedImageFile, setPotentialGeneratedImageFile] = useState(null);
    const [potentialGeneratedImageUrl, setPotentialGeneratedImageUrl] = useState('');
    const [newGeneratedImage, setNewGeneratedImage] = useState({
        isNew: false,
        urlImage: '',
    });
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenBuyCreditsgModal, setIsOpenBuyCreditsgModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);
    const [isEnoghTokensForImage, setIsEnoghTokensForImage] = useState(true);

    const disabledNextButton = currentTab === 1 && (Contradiction.score === null || Contradiction.score === undefined);

    const methodsTranslation = {
        attributeDependency: 'Attribute Dependency',
        taskUnification: 'Task Unification',
    };

    const solvingDescription = `${contradictionName} for ${project.typeName} using ${methodsTranslation[currentContradictionSolvingType]}`;

    useEffect(() => {
        setContradictionId(contradiction.id);
    }, [contradiction]);

    useEffect(() => {
        (async () => {
            let componentExists = true;
            const issuesParams = contradiction.issues ? contradiction.issues.map((issue) => issue.id) : [];
            const params = { issues: issuesParams };

            const onSuccess = (response) => {
                if (componentExists) setMessages((prev) => [...prev, ...response]);
            };

            const onError = (error) => {
                console.error(error);
                handleClose(true, { ...isPending, update: false });
            };

            await getMessagesByIssuesIds(params, onSuccess, onError);

            return () => (componentExists = false);
        })();
    }, []);

    useEffect(() => {
        if (edit) updateValues();
    }, [contradiction, edit, messages]);

    useEffect(() => {
        if (!isClosing) {
            const isUpToDate = savedContradiction.image.name === Contradiction.image.name;
            if (!isUpToDate) {
                handleSave();
            }
        }
    }, [Contradiction.image, isClosing, savedContradiction]);

    useEffect(() => {
        if (Contradiction && Contradiction.score && Contradiction.feasibleScore) {
            setIsCompletedIdea(true);
        } else {
            setIsCompletedIdea(false);
        }
    }, [Contradiction.score, Contradiction.feasibleScore]);

    const getStatus = () => {
        const currentContradiction = getContradiction();
        const {
            isIdeaFeasible,
            score,
            description,
            feasibleScore,
            issues,
            potentialBenefitsOfContradictionSolving,
            name,
            image,
        } = currentContradiction;

        if (isIdeaFeasible === false || score === 0 || feasibleScore === 0) {
            return IDEAS_IDENTIFICATION.NOT_FEASIBLE_IDEA;
        }

        if (isIdeaFeasible && score > 0) {
            return IDEAS_IDENTIFICATION.COMPLETED_IDEA;
        }

        if (
            !isIdeaFeasible &&
            score === null &&
            feasibleScore === null &&
            (issues.length === 1 || issues.length === 0) &&
            !issues[0]?.comment.trim() &&
            potentialBenefitsOfContradictionSolving.length === 1 &&
            !potentialBenefitsOfContradictionSolving[0].name.trim() &&
            !description.trim() &&
            !name.trim() &&
            !image.id
        ) {
            return IDEAS_IDENTIFICATION.EMPTY_IDEA;
        }

        return IDEAS_IDENTIFICATION.IN_PROGRESS_IDEA;
    };

    const getContradictionDetails = () => {
        const currentContradiction = getContradiction();

        return {
            name: currentContradiction.name,
            potentialBenefitsOfContradictionSolving:
                currentContradiction.potentialBenefitsOfContradictionSolving.filter((el) => el.name),
            isIdeaFeasible: currentContradiction.isIdeaFeasible,
            issues: currentContradiction.issues.filter((issue) => issue),
            description: currentContradiction.description,
            rating: currentContradiction.score,
            feasibleRating: currentContradiction.feasibleScore,
            image: currentContradiction.image,
            imagesLibrary: currentContradiction.imagesLibrary,
            status: getStatus(),
            contradictionId,
        };
    };

    const getIssuesWithMessages = () => {
        const currentContradiction = getContradiction();
        const deletedIssues = currentContradiction.deletedIssues ?? [];

        const getMessagesByIssueIdWithDeletedMessages = (issueId, issueIndex) => {
            const filteredMessages = currentContradiction.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            );
            const deletedMessages = currentContradiction.deletedIssuesMessages ?? [];

            return [...filteredMessages, ...deletedMessages];
        };

        return [
            ...currentContradiction.issues.map((issue, index) => ({
                ...issue,
                messages: getMessagesByIssueIdWithDeletedMessages(issue.id, index),
            })),
            ...deletedIssues,
        ];
    };

    const handleClose = (shouldClose, pendingState) => {
        setIsPending(pendingState);
        if (shouldClose) {
            resetContradiction();
            handleCloseDialog();
        }
    };

    const createContradiction = (shouldClose = false) => {
        if (compareIdeas(initialState, getContradiction())) {
            handleClose(shouldClose, { ...isPending, create: false });
            return;
        }

        if (isPending.create) return;

        shouldClose && setIsClosing(true);

        const params = {
            customCategories,
            id: ContradictionId,
            productId: projectId,
            teamId: teamId || '',
            ...getContradictionDetails(false),
            contradictionSolvingType: currentContradictionSolvingType,
            contradictionName: contradictionName,
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = { ...image };
            delete imageCopy.imageSource;
            return imageCopy;
        });

        delete params.image.imageSource;

        setIsPending({ ...isPending, create: true });

        const onSuccess = (response) => {
            const issues = getIssuesWithMessages() || [];

            const issuesParams = {
                ideaId: response.id || '',
                issues,
            };

            const onSuccessUpdateIssues = () => {};

            const onErrorUpdateIssues = (error) => {
                console.error(error);
            };

            if (shouldClose) {
                createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
            }

            setIsNew(false);
            setContradictionId(response.id);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        createIdea(params, onSuccess, onError);
    };

    const resetContradiction = () => {
        const currentContradiction = getContradiction();

        const issuesToDelete = currentContradiction.issues.map((issue) => ({ id: issue.id })) || [];
        const params = {
            issues: issuesToDelete,
            ideaId: ContradictionId || '',
        };
        const onSuccess = () => {};
        const onError = (error) => {
            console.error(error);
        };

        params.ideaId && createIssuesWithMessages(params, onSuccess, onError);

        currentContradiction.potentialBenefitsOfContradictionSolving.forEach((benefit) => {
            benefit.target.forEach((target) => {
                if (isDefaultCategory(target)) return;

                removeCustomCategory(target);
            });
        });

        setContradiction({ ...initialState });
        updateUploadInfo({ ...initialUpload });
        setCurrentTab(0);
        setIsNew(!edit);
    };

    const updateValues = () => {
        updateSubtractionDetails(false);
    };

    const updateSubtractionDetails = () => {
        const currentContradiction = contradiction;

        if (Object.keys(currentContradiction).length) {
            const benefitsOfContradiction = currentContradiction.potentialBenefitsOfContradictionSolving;
            const hasBenefitsOfContradiction = benefitsOfContradiction && benefitsOfContradiction.length > 0;
            const issues = currentContradiction.issues;
            const hasIssues = issues && issues.length > 0;
            const issuesMessages = [
                ...messages,
                ...(currentContradiction.issuesMessages ? currentContradiction.issuesMessages : []),
            ];
            const hasIssuesMessages = issuesMessages && issuesMessages.length > 0;
            const image = currentContradiction.image;
            const currentRating =
                currentContradiction.rating === null
                    ? null
                    : currentContradiction.rating === 0
                    ? 0
                    : currentContradiction.rating;
            const currentFeasibleRating =
                currentContradiction.feasibleRating === null
                    ? null
                    : currentContradiction.feasibleRating === 0
                    ? 0
                    : currentContradiction.feasibleRating;

            const params = {
                isIdeaFeasible: currentContradiction.isIdeaFeasible,
                score: currentRating,
                feasibleScore: currentFeasibleRating,
                potentialBenefitsOfContradictionSolving: (hasBenefitsOfContradiction && benefitsOfContradiction) || [
                    { name: '', description: '', target: [], userInfo: '' },
                ],
                issues: (hasIssues && issues) || [{ comment: '', description: '', createdByUser: '' }],
                issuesMessages: (hasIssuesMessages && issuesMessages) || [],
                name: currentContradiction.name || '',
                description: currentContradiction.description || '',
                image: image || initialState.image,
                imagesLibrary: currentContradiction.imagesLibrary || initialState.imagesLibrary,
            };

            if (image && image.name) {
                updateUploadInfo({
                    ...initialUpload,
                    name: image.originalName,
                    date: image.date,
                    size: image.size,
                    uploadName: image.name,
                    uploaded: true,
                    id: image.id,
                });
            }
            const updatedDependency = { ...getContradiction(), ...params };
            handleSetContradiction(updatedDependency);
            setSavedContradiction({ ...updatedDependency });
        } else {
            handleSetContradiction({ ...initialState });
            setSavedContradiction({ ...initialState });
        }
    };

    const selectCurrentContradictionImageFromLibrary = (index) => {
        const currentContradiction = getContradiction();
        handleSetContradiction({ ...currentContradiction, image: currentContradiction.imagesLibrary[index] });
    };
    const removeImageFromLibrary = (id) => {
        const currentContradiction = getContradiction();
        const imagesLibrary = [...currentContradiction.imagesLibrary];
        const indexOfSelectedImage = imagesLibrary.findIndex((image) => image.id === id);

        if (id === currentContradiction.image.id) {
            const newCurrentImageIndex = indexOfSelectedImage
                ? imagesLibrary.length % indexOfSelectedImage
                : imagesLibrary.length - 1;

            handleSetContradiction({
                ...currentContradiction,
                image: imagesLibrary.length - 1 ? imagesLibrary[newCurrentImageIndex] : initialState.image,
                imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
            });
            return newCurrentImageIndex;
        }
        handleSetContradiction({
            ...currentContradiction,
            imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
        });
        return imagesLibrary.findIndex((image) => image.id === currentContradiction.image.id);
    };

    const updateContradiction = (shouldClose = false) => {
        if (!ContradictionId) return;

        shouldClose && setIsClosing(true);

        const params = {
            id: ContradictionId,
            productId: projectId,
            customCategories: getCustomCategories(),
            teamId: teamId || '',
            ...getContradictionDetails(false),
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = { ...image };
            delete imageCopy.imageSource;
            return imageCopy;
        });

        delete params.image.imageSource;

        setIsPending({ ...isPending, update: true });
        const onSuccess = () => {
            handleClose(shouldClose, { ...isPending, update: false });
            setIsLoadingImage(false);
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, update: false });
        };

        const issues = getIssuesWithMessages() || [];

        const issuesParams = {
            ideaId: ContradictionId || '',
            issues,
        };

        const onSuccessUpdateIssues = () => {};

        const onErrorUpdateIssues = (error) => {
            console.error(error);
        };

        if (shouldClose && !isPending.update) {
            createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
        }

        updateIdea(params, onSuccess, onError);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetContradiction({ ...getContradiction(), [name]: value });
    };

    const handleAddIssue = () => {
        const currentDependency = getContradiction();

        const lastIssue = currentDependency?.issues[currentDependency?.issues?.length - 1];

        if (lastIssue?.comment || currentDependency?.issues?.length === 0) {
            handleSetContradiction({
                ...currentDependency,
                issues: [...currentDependency.issues, { comment: '', description: '', createdByUser: '' }],
            });
        }
    };

    const handleRemoveIssue = (index) => {
        const currentDependency = getContradiction();
        const filteredIssues = currentDependency.issues.filter((issue, issueIndex) => issueIndex !== index);
        const filteredMessages = currentDependency.issuesMessages.map((message) => {
            if (message.issueIndex > index) {
                return { ...message, issueIndex: --message.issueIndex };
            } else if (message.issueIndex === index) {
                return {};
            }
            return message;
        });
        handleSetContradiction({
            ...currentDependency,
            issues: [...filteredIssues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentDependency.deletedIssues ? currentDependency.deletedIssues : []),
                { id: currentDependency.issues[index].id },
            ],
        });
    };

    const removeLastIssue = (index, idIssues) => {
        const currentContradiction = getContradiction();
        const foundIssue = currentContradiction.issues.find((_, issueIndex) => issueIndex === index);

        let filteredMessages = [];

        if (idIssues) {
            filteredMessages = currentContradiction.issuesMessages.filter((message) => idIssues !== message.issueId);
        } else {
            filteredMessages = currentContradiction.issuesMessages.filter((message) => message.issueIndex !== index);
        }

        foundIssue.comment = '';
        foundIssue.description = '';
        foundIssue.createdByUser = '';

        handleSetContradiction({
            ...currentContradiction,
            issues: [...currentContradiction.issues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentContradiction.deletedIssues ? currentContradiction.deletedIssues : []),
                { id: currentContradiction.issues[index].id },
            ],
        });
    };

    const removeMessage = (indexMessage, idSavedMessage) => {
        const currentContradiction = getContradiction();
        let filteredMessages = [];

        if (idSavedMessage) {
            filteredMessages = currentContradiction.issuesMessages.filter((message) => idSavedMessage !== message._id);
        } else {
            filteredMessages = currentContradiction.issuesMessages.filter((message, index) => index !== indexMessage);
        }

        handleSetContradiction({
            ...currentContradiction,
            issuesMessages: [...filteredMessages],
        });
    };

    const removeLastMessage = (indexMessage, issueId, idSavedMessage) => {
        const currentContradiction = getContradiction();
        let foundMessage = {};

        if (idSavedMessage) {
            foundMessage = currentContradiction.issuesMessages.find((message) => idSavedMessage === message._id);
        } else {
            foundMessage = currentContradiction.issuesMessages.find(
                (message, index) => indexMessage === index && issueId === message.issueIndex
            );
        }

        foundMessage.message = '';

        handleSetContradiction({
            ...currentContradiction,
            issuesMessages: [...currentContradiction.issuesMessages],
        });
    };

    const handleIssueChange = (index, value, name) => {
        const currentDependency = { ...getContradiction() };
        const changedIssues = [...currentDependency.issues];
        if (name === 'comment') {
            changedIssues[index].comment = value;
            changedIssues[index].createdByUser = `${props.userFullName.name} ${props.userFullName.lastName}`;
        }

        if (name === 'description') {
            changedIssues[index].description = value;
        }

        handleSetContradiction({ ...currentDependency, issues: [...changedIssues] });
    };

    const handleAddIssueMessage = (issueId, issueIndex) => {
        const currentDependency = getContradiction();
        const filteredMessages =
            currentDependency?.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            ) || [];

        const lastMessage = filteredMessages[filteredMessages.length - 1];
        const canBeCreated = !lastMessage || lastMessage.message !== '';

        if (canBeCreated) {
            handleSetContradiction({
                ...currentDependency,
                issuesMessages: [...currentDependency.issuesMessages, { message: '', issueId, issueIndex }],
            });
        }
    };

    const handleIssueMessageChange = (value, index, userId, issueIndex) => {
        const currentContradiction = getContradiction();
        const changedIssuesMessages = [...currentContradiction.issuesMessages];
        changedIssuesMessages[index] = { message: value, userId, issueIndex };
        handleSetContradiction({ ...currentContradiction, issuesMessages: changedIssuesMessages });
    };

    const updateBenefit = (name, index, Contradiction, value) => {
        const changedBenefits = [...Contradiction.potentialBenefitsOfContradictionSolving];
        const newBenefit = { ...changedBenefits[index] };
        newBenefit[name] = value;
        changedBenefits[index] = newBenefit;
        handleSetContradiction({ ...Contradiction, potentialBenefitsOfContradictionSolving: changedBenefits });
    };

    const handleTargetChange = (index, { id, name }) => {
        const currentContradiction = getContradiction();

        const updatedTarget = [...currentContradiction.potentialBenefitsOfContradictionSolving[index].target];
        const indexUpdatedTarget = updatedTarget.findIndex((target) => target.name === name);

        if (indexUpdatedTarget === -1) {
            updatedTarget.push({ id, name });
        } else {
            updatedTarget.splice(indexUpdatedTarget, 1);
        }

        updateBenefit('target', index, currentContradiction, updatedTarget);
    };

    const handleBenefitChange = (index, value, name) => {
        const currentContradiction = getContradiction();
        updateBenefit(name, index, currentContradiction, value);
    };

    const handleDeleteOption = (changedLabel) => {
        const currentContradiction = getContradiction();

        currentContradiction.potentialBenefitsOfContradictionSolving.forEach((benefit) => {
            const updatedTargets = benefit.target.filter((targetElement) => targetElement.name !== changedLabel);
            benefit.target = updatedTargets;
        });

        handleSetContradiction({
            ...currentContradiction,
            potentialBenefitsOfContradictionSolving: currentContradiction.potentialBenefitsOfContradictionSolving,
        });

        removeCustomCategory(changedLabel);
    };

    const addBenefit = () => {
        const currentContradiction = getContradiction();
        handleSetContradiction({
            ...currentContradiction,
            potentialBenefitsOfContradictionSolving: [
                ...currentContradiction.potentialBenefitsOfContradictionSolving,
                { name: '', description: '', target: [], userInfo: '' },
            ],
        });
    };

    const removeBenefit = (index) => {
        const currentContradiction = getContradiction();
        removeCustomCategory(currentContradiction.potentialBenefitsOfContradictionSolving[index].target);
        const filteredBenefits = currentContradiction.potentialBenefitsOfContradictionSolving.filter(
            (benefit, benefitIndex) => benefitIndex !== index
        );
        handleSetContradiction({
            ...currentContradiction,
            potentialBenefitsOfContradictionSolving: [...filteredBenefits],
        });
    };

    const removeLastBenefit = (index) => {
        const currentContradiction = getContradiction();
        removeCustomCategory(currentContradiction.potentialBenefitsOfContradictionSolving[index].target);
        const foundBenefit = currentContradiction.potentialBenefitsOfContradictionSolving.find(
            (benefit, benefitIndex) => benefitIndex === index
        );

        foundBenefit.name = '';
        foundBenefit.description = '';
        foundBenefit.target = [];
        foundBenefit.userInfo = '';

        handleSetContradiction({
            ...currentContradiction,
            potentialBenefitsOfContradictionSolving: [...currentContradiction.potentialBenefitsOfContradictionSolving],
        });
    };

    const handleSetCurrentTab = (value) => {
        setCurrentTab(value);
        handleSave();
    };

    const handleSave = () => {
        const upToDate = compareIdeas(savedContradiction, Contradiction);

        if (!upToDate) {
            const updatedSubtraction = { ...getContradiction() };

            setSavedContradiction(updatedSubtraction);
            isNew ? createContradiction() : updateContradiction();
        }
    };

    const compareIdeas = (firstItem, secondItem) => {
        const keys = Object.keys(firstItem);
        let isEqual = true;

        for (let key of keys) {
            if (key === 'potentialBenefitsOfContradictionSolving') {
                const firstBenefit =
                    firstItem[key] &&
                    firstItem[key].filter(
                        (benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo
                    );
                const secondBenefit =
                    secondItem[key] &&
                    secondItem[key].filter(
                        (benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo
                    );

                if (firstBenefit.length !== secondBenefit.length) {
                    isEqual = false;
                    break;
                }

                for (let benefit of firstBenefit) {
                    const foundBenefit = secondBenefit.find(
                        (item) =>
                            item.name === benefit.name &&
                            item.target === benefit.target &&
                            item.description === benefit.description &&
                            item.userInfo === benefit.userInfo
                    );
                    if (!foundBenefit) {
                        isEqual = false;
                        break;
                    }
                }

                continue;
            }

            if (key === 'issues') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'issuesMessages') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'image') {
                if (firstItem[key].name !== secondItem[key].name) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (firstItem[key] !== secondItem[key]) {
                isEqual = false;
                break;
            }
        }

        return isEqual;
    };

    const prevEnabled = () => {
        if (currentTab === 0) {
            return false;
        }

        return true;
    };

    const nextEnabled = () => {
        if (currentTab === 2) {
            return false;
        }

        return true;
    };

    const isCompleted = () => {
        const currentContradiction = getContradiction();
        return (
            currentContradiction.isIdeaFeasible === false ||
            (currentContradiction.isIdeaFeasible === true && currentContradiction.score > 0)
        );
    };

    const handleSetContradiction = (value) => {
        setContradiction(value);
    };

    const getContradiction = () => {
        return { ...Contradiction, image: { ...Contradiction.image } };
    };

    const handleImageUpload = (file, imageSource = null) => {
        const CancelToken = axios.CancelToken;

        const formData = new FormData();
        formData.append('image', file, file.name);
        updateUploadInfo({ progress: 0, cancel: null, uploaded: false, name: file.name, size: file.size });

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateUploadInfo({ progress: completed });
            },
            cancelToken: new CancelToken(function executor(c) {
                updateUploadInfo({ cancel: c });
            }),
        };

        const onSuccess = (response) => {
            updateUploadInfo({ uploaded: true, date: new Date(), uploadName: response.filename, id: response.imageId });
            const image = {
                name: response.filename,
                originalName: file.name,
                id: response.imageId,
                imageSource,
            };

            const currentContradiction = getContradiction();
            if (!currentContradiction.imagesLibrary.length) {
                handleSetContradiction({
                    ...currentContradiction,
                    imagesLibrary: [...currentContradiction.imagesLibrary, image],
                    image,
                });
                return;
            }
            handleSetContradiction({
                ...currentContradiction,
                imagesLibrary: [...currentContradiction.imagesLibrary, image],
            });
        };

        const onError = (error) => {
            console.error(error);
            if (uploadInfo.name) {
                updateUploadInfo({ ...initialUpload });
            }
        };

        setIsLoadingImage(true);
        uploadImage(formData, config, onSuccess, onError);
    };

    const handleCancelClick = () => {
        setNewGeneratedImage({
            isNew: false,
            urlImage: '',
        });
        uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
        updateUploadInfo({ ...initialUpload });

        const currentSubtraction = getContradiction();
        handleSetContradiction({ ...currentSubtraction, image: initialState.image });
    };

    const updateUploadInfo = (value) => {
        if (value) {
            setUploadInfo((state) => ({ ...state, ...value }));
        } else {
            setUploadInfo((state) => ({ ...state, ...initialUpload }));
        }
    };

    const currentUploadInfo = uploadInfo;

    const handleCloseDialog = () => {
        setIsClosing(true);
        const upload = uploadInfo;
        if (upload.name && !upload.uploaded) {
            upload.cancel && upload.cancel('Canceled by user');
        }

        setUploadInfo({ ...initialUpload });
        closeDialog && closeDialog();
    };

    const matrixBodyClass = [1, 2].includes(currentTab) ? ' benefits-container' : '';

    const addCustomCategory = (name) => {
        const foundCategory = customCategories.find((category) => category.name === name);
        if (foundCategory) {
            foundCategory.count++;
            return;
        }

        const currentId = customCategories[customCategories.length - 1]?.id;

        setCustomCategories([
            ...customCategories,
            {
                id: _.isNumber(currentId) ? currentId + 1 : 3,
                name,
                count: 1,
            },
        ]);
    };

    const removeCustomCategory = (name) => {
        const updatedCategories = customCategories.filter((category) => category.name !== name);
        setCustomCategories(updatedCategories);
    };

    const renameCustomCategory = (oldName, newName) => {
        const foundCategoryIndex = customCategories.findIndex((category) => category.name === newName);
        if (foundCategoryIndex !== -1) {
            customCategories[foundCategoryIndex].count++;

            return;
        }

        const Contradiction = getContradiction();

        Contradiction.potentialBenefitsOfContradictionSolving.forEach((benefit) => {
            const currentTargetIndex = benefit.target.findIndex((target) => target.name === oldName);

            if (currentTargetIndex !== -1) {
                benefit.target.splice(currentTargetIndex, 1, {
                    id: benefit.target[currentTargetIndex].id,
                    name: newName,
                });
            }
        });

        handleSetContradiction({
            ...Contradiction,
            potentialBenefitsOfContradictionSolving: Contradiction.potentialBenefitsOfContradictionSolving,
        });

        const copiedArray = [...customCategories];
        const findOldCategoryIndex = customCategories.findIndex((category) => category.name === oldName);

        const { count, id } = customCategories[findOldCategoryIndex];

        copiedArray.splice(findOldCategoryIndex, 1, { id, count, name: newName });
        setCustomCategories(copiedArray);
    };

    const getCustomCategories = () => {
        return customCategories.filter((category) => category.count >= 0);
    };

    const addIdeasName = (ideasName) => {
        setContradiction({ ...Contradiction, name: ideasName });
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const checkDescription = () => {
        const currentContradiction = getContradiction();

        if (!currentContradiction.description) {
            setError({
                openErrorModal: true,
                message: getTranslation('MESSAGE_MODAL_CHECK_DESCRIPTION_TEXT'),
                headerError: getTranslation('MESSAGE_MODAL_CHECK_DESCRIPTION_HEADER'),
            });
            return false;
        }

        return true;
    };

    const generateNames = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
            closeErrorWindow();
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = error.response && error.response.data;

            if (code === '30840' || code === '30841') {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== '30841');
                return;
            }

            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        const currentContradiction = getContradiction();

        getGeneratedNamesForIdea(
            {
                projectName: projectName,
                productType,
                projectId,
                descriptionOfIdea: currentContradiction.description || solvingDescription,
                language,
            },
            onSuccess,
            onError
        );
    };

    const generateContradictionSolving = () => {
        const onSuccess = (data) => {
            handleSetContradiction({ ...getContradiction(), description: data });
            setSuggestedLoading(false);
            closeErrorWindow();
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = error.response && error.response.data;

            if (code === '30840' || code === '30841') {
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== '30841');
                return;
            }

            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        getContradictionSolving(
            {
                prompt: "Using the “Contradiction” method of Systematic Inventive Thinking, provide a solution to the [selectedContadiction] for [typeName] using the [solutionMethod]. Don't make introductions, write the gist right away, don't use markdown symbols such as ### and **.",
                selectedContadiction: contradictionName,
                solutionMethod: methodsTranslation[currentContradictionSolvingType],
                typeName: project.typeName,
            },
            onSuccess,
            onError
        );
    };

    const selectGenerateOption = (nameOption) => {
        setSelectedGenerateOptions((prev) => {
            const prevSelectedOption = new Set(prev);
            const key = `${nameOption}`;
            prev.has(key) ? prevSelectedOption.delete(key) : prevSelectedOption.add(key);
            return prevSelectedOption;
        });
    };

    const addGenerateBenefits = () => {
        const benefits = [];

        suggestedOptions.map((benefit) => {
            if (selectedGenerateOptions.has(`${benefit}`)) {
                const params = {
                    name: benefit.includes('：') ? benefit.split('：')[0] : benefit.split(':')[0],
                    description: benefit.includes('：') ? benefit.split('：')[1] : benefit.split(':')[1],
                    target: [],
                    userInfo: 'AI',
                };
                benefits.push(params);
            }
        });

        setContradiction({
            ...Contradiction,
            potentialBenefitsOfContradictionSolving: [
                ...benefits,
                ...Contradiction.potentialBenefitsOfContradictionSolving,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const generateBenefits = () => {
        if (!solvingDescription) return;

        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
            closeErrorWindow();
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = error.response && error.response.data;

            if (code === '30840' || code === '30841') {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== '30841');
                return;
            }

            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        getGeneratedBenefits(
            {
                projectName: project.name,
                projectId: projectId,
                descriptionOfIdea: solvingDescription,
                language,
            },
            onSuccess,
            onError
        );
    };

    const closeGenerateModal = () => {
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: '',
        });
    };

    const handleChangeScore = (e) => {
        setContradiction({ ...Contradiction, score: e });
    };

    const handleChangeFeasibleScore = (e) => {
        setContradiction({ ...Contradiction, isIdeaFeasible: e > 0, feasibleScore: e });
    };

    const getMessageForCheckRating = () => {
        return (
            <p className='flex-column mt-25px'>
                <span style={{ margin: '0' }}>{getTranslation('RATING_REQUIRED_MODAL_NOT_RATE_IMPACT')}</span>
                <span>{getTranslation('RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY')}</span>
            </p>
        );
    };

    const checkRating = () => {
        if (
            currentTab === 2 &&
            (Contradiction.score === null || Contradiction.score === undefined) &&
            (Contradiction.feasibleScore === null || Contradiction.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getMessageForCheckRating(),
                headerError: getTranslation('RATING_REQUIRED_MODAL_HEADER'),
            });
            return;
        }

        if (
            currentTab === 2 &&
            Contradiction.score &&
            (Contradiction.feasibleScore === null || Contradiction.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation('RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY'),
                headerError: getTranslation('RATING_REQUIRED_MODAL_HEADER'),
            });
            return;
        }

        if (
            currentTab === 2 &&
            Contradiction.feasibleScore &&
            (Contradiction.score === null || Contradiction.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getTranslation('RATING_REQUIRED_MODAL_NOT_RATE_IMPACT'),
                headerError: getTranslation('RATING_REQUIRED_MODAL_HEADER'),
            });
            return;
        }

        closeErrorWindow();

        isNew ? createContradiction(true) : updateContradiction(true);
    };

    const handleRightClick = () => {
        if (currentTab === 1 && (Contradiction.score === null || Contradiction.score === undefined)) {
            setError({
                openErrorModal: true,
                message: getTranslation('RATING_REQUIRED_MODAL_NOT_RATE_IMPACT'),
                headerError: getTranslation('RATING_REQUIRED_MODAL_HEADER'),
            });
            return;
        }

        closeErrorWindow();
        handleSetCurrentTab(currentTab + 1);
    };

    const handleLeftClick = () => {
        handleSetCurrentTab(currentTab - 1);
    };

    const changeCurrentTab = (index) => {
        setCurrentTab(index);
    };

    const generateChallenges = () => {
        if (!solvingDescription) return;

        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
            closeErrorWindow();
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = error.response && error.response.data;

            if (code === '30840' || code === '30841') {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== '30841');
                return;
            }

            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        getGeneratedChallenges(
            {
                projectName: project.name,
                projectId: projectId,
                descriptionOfIdea: solvingDescription,
                language,
            },
            onSuccess,
            onError
        );
    };

    const addGenerateChallenges = () => {
        const generatedIssues = [];

        suggestedOptions.map((issue) => {
            if (selectedGenerateOptions.has(`${issue}`)) {
                const params = {
                    comment: issue.includes('：') ? issue.split('：')[0] : issue.split(':')[0],
                    description: issue.includes('：') ? issue.split('：')[1] : issue.split(':')[1],
                    createdByUser: 'AI',
                };
                generatedIssues.push(params);
            }
        });

        setContradiction({
            ...Contradiction,
            issues: [...generatedIssues, ...Contradiction.issues],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const convertImage = async (imageBase64) => {
        try {
            const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
            const blobImage = await base64Response.blob();

            let dt = new DataTransfer();
            dt.items.add(new File([blobImage], 'image.png', { type: blobImage.type }));
            const fileImage = dt.files[0];
            const bufferImage = await fileImage.arrayBuffer();

            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = `data:image/png;base64,${Buffer.from(bufferImage).toString('base64')}`;
                setPotentialGeneratedImageUrl(imageUrl);
            };

            reader.readAsArrayBuffer(fileImage);

            return fileImage;
        } catch (error) {
            setError({
                openErrorModal: true,
                message: error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
        }
    };

    const handleAcceptPotentialImage = () => {
        setGeneratedImage(potentialGeneratedImageUrl);
        handleImageUpload(potentialGeneratedImageFile, potentialGeneratedImageUrl);
        setIsOpenGenerateImageModal(false);
    };

    const generateImage = () => {
        if (!checkDescription()) return;

        setIsOpenGenerateImageModal(true);

        const onSuccess = async (imageBase64) => {
            setNewGeneratedImage({
                isNew: true,
                urlImage: `data:image/png;base64,${imageBase64}`,
            });
            const fileImage = await convertImage(imageBase64);

            setPotentialGeneratedImageFile(fileImage);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = error.response && error.response.data;

            if (code === '30840' || code === '30841') {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                setIsEnoghTokensForImage(code !== '30841');
                return;
            }

            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        const currentContradiction = getContradiction();

        getGeneratedImage(
            {
                descriptionOfIdea: currentContradiction.description,
                language,
            },
            onSuccess,
            onError
        );
    };

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='subtraction-dialog-wrapper'>
                <EurekaModalHeader
                    closeDialog={handleCloseDialog}
                    subtraction={getContradiction()}
                    currentTab={currentTab}
                    setCurrentTab={handleSetCurrentTab}
                    isCompletedIdea={isCompletedIdea}
                    projectName={projectName}
                    changeCurrentTab={changeCurrentTab}
                    productName={productName}
                    solvingDescription={solvingDescription}
                />

                <div className={'subtraction-dialog-body-eureka flex-column' + matrixBodyClass}>
                    <EurekaModalTabs
                        generateContradictionSolving={generateContradictionSolving}
                        selectCurrentContradictionImageFromLibrary={selectCurrentContradictionImageFromLibrary}
                        removeImageFromLibrary={removeImageFromLibrary}
                        handleAcceptPotentialImage={handleAcceptPotentialImage}
                        potentialGeneratedImageUrl={potentialGeneratedImageUrl}
                        currentTab={currentTab}
                        solvingDescription={solvingDescription}
                        contradiction={getContradiction()}
                        setContradiction={handleSetContradiction}
                        handleChange={handleChange}
                        userFullName={props.userFullName}
                        userId={props.userId}
                        addBenefit={addBenefit}
                        removeBenefit={removeBenefit}
                        removeLastBenefit={removeLastBenefit}
                        handleBenefitChange={handleBenefitChange}
                        handleTargetChange={handleTargetChange}
                        addIssue={handleAddIssue}
                        removeIssue={handleRemoveIssue}
                        removeLastIssue={removeLastIssue}
                        removeMessage={removeMessage}
                        removeLastMessage={removeLastMessage}
                        handleIssueChange={handleIssueChange}
                        uploadInfo={currentUploadInfo}
                        newGeneratedImage={newGeneratedImage}
                        handleCancelClick={handleCancelClick}
                        handleFileSelect={handleImageUpload}
                        customCategories={getCustomCategories()}
                        isLoadingImage={isLoadingImage}
                        addIssueMessage={handleAddIssueMessage}
                        handleIssueMessageChange={handleIssueMessageChange}
                        handleGenerateNames={generateNames}
                        suggestedLoading={suggestedLoading}
                        addIdeasName={addIdeasName}
                        handleChangeScore={handleChangeScore}
                        handleChangeFeasibleScore={handleChangeFeasibleScore}
                        suggestedOptions={suggestedOptions}
                        openGenerateModal={openGenerateModal}
                        closeGenerateModal={closeGenerateModal}
                        selectGenerateOption={selectGenerateOption}
                        selectedGenerateOptions={selectedGenerateOptions}
                        addGenerateBenefits={addGenerateBenefits}
                        handleGenerateBenefits={generateBenefits}
                        handleGenerateChallenges={generateChallenges}
                        addGenerateChallenges={addGenerateChallenges}
                        handleGenerateImage={generateImage}
                        isOpenGenerateImageModal={isOpenGenerateImageModal}
                        closeDialogGenerateImage={() => setIsOpenGenerateImageModal(false)}
                        generatedImage={generatedImage}
                        handleDeleteOption={handleDeleteOption}
                        handleRenameOption={renameCustomCategory}
                        addCustomCategory={addCustomCategory}
                    />
                </div>

                <EurekaModalFooter
                    resetSubtractionsMatrix={resetContradiction}
                    prevEnabled={prevEnabled}
                    nextEnabled={nextEnabled}
                    isCompleted={isCompleted}
                    handleRightClick={handleRightClick}
                    handleLeftClick={handleLeftClick}
                    disabledNextButton={disabledNextButton}
                    checkRating={checkRating}
                    currentTab={currentTab}
                />

                {error.openErrorModal && (
                    <ErrorMessageModal
                        message={error.message}
                        closeModalWindow={closeErrorWindow}
                        textButton={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                        handleButton={closeErrorWindow}
                        header={error.headerError}
                    />
                )}

                {paymentInfo.key && (
                    <CheckoutModal
                        isOpenAISubscription
                        closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: '' }))}
                        subscriptionInfo={paymentInfo}
                        history={history}
                        mode='payment'
                        setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                    />
                )}

                {isOpenBuyCreditsgModal && (
                    <BuyMoreAICreditsModal
                        setPaymentInfo={setPaymentInfo}
                        closeDialog={() => setIsOpenBuyCreditsgModal(false)}
                    />
                )}

                {isOpenWarningModal && (
                    <WarningNotTokensModal
                        closeDialog={() => {
                            setIsOpenWarningModal(false);
                        }}
                        openBuyModal={() => setIsOpenBuyCreditsgModal(true)}
                        history={history}
                        isEnoghTokensForImage={isEnoghTokensForImage}
                    />
                )}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    setGeneratedOpenAIRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContradictionEditModal);
