import React, { useEffect, useState } from 'react';
import ProjectTopBar from '../common/ProjectTopBar';
import { connect } from 'react-redux';
import { createMultiplecontradiction } from '../../api/contradictions';
import { getFullProjectInfo } from '../../actions/projectActions';
import { setPreference } from '../../actions/preferencesActions';
import ContradictionMatrixHeader from './ContradictionsMatrixHeader';
import ContradictionEditModal from '../dialogs/ContradictionEditModal';
import ContradictionsTable from './ContradictionsTable';
import { getContradiction } from '../../api/ai';
import CustomDropdown from '../inputs/CustomDropdown';
import { ReactComponent as AIGenerateIcon } from '../../assets/images/ai-generate-icon.svg';
import Loader from '../common/Loader';
import './styles.css';

const modalsInitialState = {
    edit: false,
    delete: false,
    contradictionGenerate: false,
};

export const numbersOfGeneratedContradictionsSolvings = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
];

const Contradiction = ({ match, auth, history, product, getProjectInfo, contradictions, contradictionsSolvings }) => {
    const [modalOpen, setModalOpen] = useState(modalsInitialState);
    const [currentContradictionSolvingId, setCurrentContradictionSolvingId] = useState({});
    const [currentContradictionId, setCurrentContradictionId] = useState('');
    const [currentContradictionSolving, setCurrentContradictionSolving] = useState({});
    const [currentContradictionSolvingType, setCurrentContradictionSolvingType] = useState('');
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [numberOfGeneratedContradictions, setNumberOfGeneratedContradictions] = useState(1);
    const openEditModal = () => {
        setModalOpen({ ...modalOpen, edit: true });
    };

    const { user } = auth;

    const handleCloseModals = () => {
        setModalOpen({
            component: false,
            contradictionGenerate: false,
            edit: false,
        });
        getProjectInfo(projectId);
    };

    const generateContradictions = () => {
        const onSuccess = (data) => {
            createNewContadictions(data);
            setSuggestedLoading(false);
            setNumberOfGeneratedContradictions(1);
        };

        const onError = (error) => {
            setSuggestedLoading(false);
        };

        setSuggestedLoading(true);

        getContradiction(
            {
                projectId,
                typeName: product.typeName,
                type: 'contradictionsGeneration',
                N: numberOfGeneratedContradictions,
                prompt: 'Using the “Contradiction” method of Systematic Inventive Thinking, generate [N] Contradictions for [typeName].For each of the [N] contradictions, display the following: In the title: “Name of contradiction”, that should be like: "First thing Vs second thing", without word "contradiction”". In the space below the above title line, display in bullet points the text describing the two requirements, “Requirement 1” and “Requirement 2”. Dont make a blank line between contradiction title and requierements. Numbers of the requierements always must be 2. Dont make introductions, write the gist right away, dont use markdown symbols such as ### and **',
            },
            onSuccess,
            onError
        );
    };

    const createNewContadictions = async (contradictions) => {
        const params = {
            contradictionsInfo: contradictions.map((contradiction) => {
                const [contradictionTitle, firstRequirement, secondRequirement] = contradiction.split('\n');
                return {
                    contradictionTitle,
                    contradictionRequirements: [firstRequirement, secondRequirement],
                    productId: product.id,
                    teamId: product.teamId || '',
                };
            }),
        };
        await createMultiplecontradiction(params);
        getProjectInfo(projectId);
        handleCloseModals();
    };

    const projectId = match.params.projectId;

    useEffect(() => {
        getProjectInfo(projectId);
    }, [getProjectInfo, projectId]);

    useEffect(() => {
        if (currentContradictionSolvingId) {
            setCurrentContradictionSolving(
                contradictionsSolvings.find(
                    (contradictionSolving) => contradictionSolving.id === currentContradictionSolvingId
                )
            );

            return;
        }

        setCurrentContradictionSolving({});
    }, [currentContradictionSolvingId, contradictionsSolvings]);

    return (
        <div>
            <ProjectTopBar match={match} history={history} currentProjectName={product.name} />

            <div className='contradictions-wrapper'>
                <ContradictionMatrixHeader />
                {contradictions.length < 3 && (
                    <div className='ai_button_container'>
                        <div className='flex flex-column'>
                            <span>Number of generated contradictions:</span>
                            <CustomDropdown
                                value={numberOfGeneratedContradictions}
                                options={
                                    contradictions.length > 0
                                        ? numbersOfGeneratedContradictionsSolvings.toSpliced(
                                              contradictions?.length * -1
                                          )
                                        : numbersOfGeneratedContradictionsSolvings
                                }
                                className='white-no-wrap mb-20 mr-15'
                                handleChange={(option) => setNumberOfGeneratedContradictions(option)}
                                isTooltip={true}
                            />
                        </div>

                        <button
                            className={`button-ai ${
                                contradictions.length > 4 && 'eureka-modal__button-ai_image-disabled'
                            }`}
                            onClick={() => generateContradictions()}
                            disabled={contradictions.length > 4 || suggestedLoading}
                        >
                            <AIGenerateIcon
                                style={{
                                    color: contradictions.length > 4 ? 'white' : '#CC4B4F',
                                    width: 28,
                                    marginRight: '10px',
                                }}
                            />
                            GENERATE {numberOfGeneratedContradictions} CONTRADICTION
                            {numberOfGeneratedContradictions !== 1 && 'S'}
                        </button>
                    </div>
                )}

                {suggestedLoading ? (
                    <Loader />
                ) : (
                    <ContradictionsTable
                        history={history}
                        contradictions={contradictions}
                        contradictionsSolvings={contradictionsSolvings}
                        openEditModal={openEditModal}
                        setCurrentContradictionSolvingId={setCurrentContradictionSolvingId}
                        setCurrentContradictionId={setCurrentContradictionId}
                        setCurrentContradictionSolvingType={setCurrentContradictionSolvingType}
                    />
                )}

                {modalOpen.edit && (
                    <ContradictionEditModal
                        currentContradictionSolvingType={currentContradictionSolvingType}
                        contradiction={currentContradictionSolving || {}}
                        currentContradiction={contradictions.find(
                            (contradiction) => currentContradictionId === contradiction.id
                        )}
                        contradictionId={currentContradictionId}
                        project={product}
                        closeDialog={handleCloseModals}
                        userFullName={{ name: user.name, lastName: user.lastName }}
                        userId={user.id}
                        edit={!!currentContradictionSolvingId}
                        projectName={product?.typeName || ''}
                        projectId={projectId}
                        teamId={product.teamId}
                        categories={product.customCategories}
                        contradictionName={
                            contradictions.find((contradiction) => contradiction.id === currentContradictionId)
                                .contradictionTitle
                        }
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        prop: state,
        auth: state.auth,
        errors: state.project.errors,
        product: state.project.product,
        contradiction: state.project.contradiction,
        isLoading: state.project.isLoading,
        contradictions: state.project.contradictions,
        contradictionsSolvings: state.project.contradictionsSolvings,
    };
};

export default connect(mapStateToProps, { getProjectInfo: getFullProjectInfo, setPreference })(Contradiction);
