import React, { useEffect, useState } from 'react';
import './styles.css';
import { connect } from 'react-redux';

import { getProjectInfo } from '../../actions/projectActions';
import { isProjectEditable } from '../../common/project';

import ProjectsDashboardHeader from './ProjectDashboardHeader';
import ComponentsCard from './cards/ComponentsCard';
import AttributesCard from './cards/AttributesCard';
import DependenciesCard from './cards/DependenciesCard';
import IdeasCard from './cards/IdeasCard';
import MessageShowNotFound from '../common/MessageShowNotFound';
import ProjectTopBar from '../common/ProjectTopBar';
import ProjectEditModal from '../dialogs/ProjectEditModal';

import CalendarIcon from '../../assets/images/calendar-icon.svg';
import AssigneeIcon from '../../assets/images/assignee-icon.svg';

import { ReactComponent as PlusIconSmall } from '../../assets/images/plus-icon-20px.svg';

import Loader from '../common/Loader';

const projectInitialState = {
    product: {},
    attributes: [],
    components: [],
    detailsMatrix: [],
    subtractions: [],
    replacements: [],
    multiplications: [],
    eurekaIdeas: [],
};

const ProjectsDashboard = ({
    auth,
    isLoading,
    product,
    attributes,
    components,
    detailsMatrix,
    subtractions,
    replacements,
    multiplications,
    contradictions,
    contradictionsSolvings,
    eurekaIdeas,
    cellsCount,
    errors,
    history,
    match,
    getProjectInfo,
}) => {
    const projectId = match.params.projectId;
    const [projectData, setProjectData] = useState(projectInitialState);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getProjectInfo(projectId);
    }, [projectId, getProjectInfo]);

    useEffect(() => {
        setProjectData((projectData) => ({
            ...projectData,
            product,
            attributes,
            components,
            detailsMatrix,
            subtractions,
            replacements,
            multiplications,
            eurekaIdeas,
            contradictions,
            contradictionsSolvings,
        }));
    }, [
        detailsMatrix,
        subtractions,
        replacements,
        multiplications,
        eurekaIdeas,
        contradictions,
        contradictionsSolvings,
        components,
        attributes,
        product,
    ]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const navigateToReports = (idea, isReversed) => {
        history.push(`/project/${projectId}/report?id=${idea.id}&isReversed=${Boolean(isReversed)}`);
    };

    const navigateToComponents = () => {
        history.push(`/project/${projectId}/components`);
    };

    const canEditProject = isProjectEditable(product, auth.user.id);
    const showNotFoundError = errors && errors.response && errors.response.status === 404;

    return (
        <div>
            <ProjectTopBar match={match} history={history} currentProjectName={projectData.product.name} />

            <div className='projects-dashboard-wrapper'>
                {!errors && (
                    <>
                        {isLoading && <Loader />}
                        {!isLoading && (
                            <>
                                <ProjectsDashboardHeader
                                    canEdit={canEditProject}
                                    project={product}
                                    CalendarIcon={CalendarIcon}
                                    AssigneeIcon={AssigneeIcon}
                                    language={auth.userInfo.language}
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    getProjectInfo={getProjectInfo}
                                    userId={auth.user.id}
                                />
                                {Boolean(cellsCount.explored > 0 || projectData?.eurekaIdeas?.length) && (
                                    <div className='project-dashboard-body flex'>
                                        <div className='flex flex-column project-dashboard-cards-container'>
                                            <DependenciesCard
                                                PlusIconSmall={PlusIconSmall}
                                                dependencies={cellsCount}
                                                projectId={projectId}
                                                history={history}
                                                projectType={product.type}
                                            />

                                            <ComponentsCard
                                                projectType={product.type}
                                                components={projectData.components}
                                                PlusIconSmall={PlusIconSmall}
                                                projectId={projectId}
                                                history={history}
                                                navigateToComponents={navigateToComponents}
                                            />
                                            <AttributesCard
                                                attributes={projectData.attributes}
                                                PlusIconSmall={PlusIconSmall}
                                                projectId={projectId}
                                                history={history}
                                                navigateToComponents={navigateToComponents}
                                                projectType={product.type}
                                            />
                                        </div>

                                        <IdeasCard
                                            projectType={product.type}
                                            projectName={projectData.product.name}
                                            detailsMatrix={projectData.detailsMatrix}
                                            subtractions={projectData.subtractions}
                                            replacements={projectData.replacements}
                                            multiplications={projectData.multiplications}
                                            eurekaIdeas={projectData.eurekaIdeas}
                                            components={projectData.components}
                                            attributes={projectData.attributes}
                                            contradictions={projectData.contradictions}
                                            contradictionsSolvings={projectData.contradictionsSolvings}
                                            PlusIconSmall={PlusIconSmall}
                                            projectId={projectId}
                                            history={history}
                                            navigateToReports={navigateToReports}
                                        />
                                    </div>
                                )}

                                {cellsCount.explored === 0 && (
                                    <div className='project-dashboard-body flex'>
                                        <ComponentsCard
                                            components={projectData.components}
                                            PlusIconSmall={PlusIconSmall}
                                            history={history}
                                            projectId={projectId}
                                            projectType={product.type}
                                            navigateToComponents={navigateToComponents}
                                        />
                                        <AttributesCard
                                            projectType={product.type}
                                            attributes={projectData.attributes}
                                            PlusIconSmall={PlusIconSmall}
                                            projectId={projectId}
                                            history={history}
                                            navigateToComponents={navigateToComponents}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

                {showNotFoundError && <MessageShowNotFound history={history} />}

                {modalOpen && (
                    <ProjectEditModal
                        isProcessType={product.type === 'process'}
                        edit={true}
                        closeDialog={handleCloseModal}
                        currentProject={product}
                        onSuccess={() => getProjectInfo(projectId)}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    product: state.project.product,
    attributes: state.project.attributes,
    components: state.project.components,
    detailsMatrix: state.project.detailsMatrix,
    subtractions: state.project.subtractions,
    replacements: state.project.replacements,
    multiplications: state.project.multiplications,
    contradictions: state.project.contradictions,
    contradictionsSolvings: state.project.contradictionsSolvings,
    eurekaIdeas: state.project.eurekaIdeas,
    cellsCount: state.project.cellsCount,
    isLoading: state.project.isLoading,
    errors: state.project.errors,
});

export default connect(mapStateToProps, { getProjectInfo })(ProjectsDashboard);
