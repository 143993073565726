import React, { useState, useMemo } from 'react';
import { contradictionSolvingsTypes } from './constants';
import CellDropdown from '../DependencyMatrix/CellDropdown';
import Tooltip from '../common/Tooltip';
import './styles.css';

const colors = {
    0: '#ffffff',
    1: '#ffcd67',
    2: '#f9ae9f',
    3: {
        1: '#daf1e1',
        2: '#b5e3c3',
        3: '#91d5a5',
        4: '#6cc687',
        5: '#47b869',
    },
};

const ContradictionsTable = ({
    contradictions,
    openEditModal,
    contradictionsSolvings,
    setCurrentContradictionSolvingId,
    setCurrentContradictionId,
    setCurrentContradictionSolvingType,
}) => {
    const [currentActiveCell, setCurrentActiveCell] = useState([]);

    const contradictionsWithSolvings = useMemo(
        () =>
            contradictions.reduce((acc, contradction) => {
                return {
                    ...acc,
                    [contradction.id]: {
                        taskUnification: contradictionsSolvings.filter(
                            (contradictionsSolving) =>
                                contradictionsSolving.contradictionId === contradction.id &&
                                contradictionsSolving.contradictionSolvingType ===
                                    contradictionSolvingsTypes.TASK_UNIFICATION
                        ),
                        attributeDependency: contradictionsSolvings.filter(
                            (contradictionsSolving) =>
                                contradictionsSolving.contradictionId === contradction.id &&
                                contradictionsSolving.contradictionSolvingType ===
                                    contradictionSolvingsTypes.ATTRIBUTE_DEPENDENCY
                        ),
                    },
                };
            }, {}),
        [contradictions, contradictionsSolvings]
    );

    const getCellColor = (contadictionSolvings) => {
        if (!contadictionSolvings || !contadictionSolvings.length) {
            return colors[0];
        }

        const statusesAndRatings = contadictionSolvings.map((contadictionSolving) => {
            const { status, rating } = contadictionSolving;
            return { status, rating };
        });

        const maxStatus = Math.max(...statusesAndRatings.map(({ status }) => status));
        const maxRating = Math.max(...statusesAndRatings.map(({ rating }) => rating));

        const status = maxStatus;
        const rating = maxRating;

        if (status < 3 && status >= 0) {
            return colors[status];
        }

        if (status >= 3) {
            const ratingValue = rating > 5 ? 5 : rating;
            return colors[3][ratingValue];
        }

        return colors[0];
    };

    const currentCellSetter = (value) => {
        setCurrentActiveCell([value.row, value.column]);
    };

    const handleOpenEditModal = (row, column, contadictionSolving) => {
        setCurrentContradictionId(contradictions[row].id);
        openEditModal();
        setCurrentContradictionSolvingId(contadictionSolving?.id);
    };

    return (
        <div className='contradiction-matrix-table'>
            <table>
                {contradictions.map(({ id, contradictionTitle, contradictionRequirements }, index) => {
                    let attributeDependfencyprefix = 'are';
                    let attributeDependencysolutionForm = 'solutions';

                    let taskUnificationDependencyprefix = 'are';
                    let taskUnificationDependencysolutionForm = 'solutions';
                    
                    const attributeDependencySolutionLength = contradictionsWithSolvings[id].attributeDependency?.length;
                    const taskUnificationSolutionLength =
                        contradictionsWithSolvings[id].taskUnification?.length;

                    if (attributeDependencySolutionLength === 1) {
                        attributeDependfencyprefix = 'is';
                        attributeDependencysolutionForm = 'solution';
                    }

                    if (taskUnificationSolutionLength === 1) {
                        taskUnificationDependencyprefix = 'is';
                        taskUnificationDependencysolutionForm = 'solution';
                    }

                    return (
                        <>
                            <tr>
                                <td className='contradiction_name_table_cell'>
                                    {`Contradiction ${index + 1}: `}
                                    <span className='editable_content'>{contradictionTitle}</span>
                                </td>
                                <td>Attribute Dependency</td>
                                <td>Task Unification</td>
                            </tr>
                            <tr>
                                <td className='contradiction_requirements_table_cell'>
                                    <ul className='flex flex-column'>
                                        {contradictionRequirements.map((requirement) => (
                                            <li className='editable_content'>
                                                <strong>{requirement?.split(':')[0]}</strong>:
                                                {requirement?.split(':')[1]}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td className='contradiction_table_idea_cell'>
                                    <Tooltip
                                        message={
                                            !(currentActiveCell[0] === index && currentActiveCell[1] === 0) &&
                                            `There ${attributeDependfencyprefix} ${attributeDependencySolutionLength} ${attributeDependencysolutionForm} for this contradiction by this method`
                                        }
                                        tooltipMessageClass='my-projects-tooltip'
                                        position='top'
                                    >
                                        <button
                                            className={'contradiction_idea_btn'}
                                            style={{
                                                backgroundColor: `${getCellColor(
                                                    contradictionsWithSolvings[id].attributeDependency
                                                )}`,
                                            }}
                                            onClick={() => {
                                                setCurrentContradictionSolvingType('attributeDependency');
                                                setCurrentActiveCell([index, 0]);
                                            }}
                                        >
                                            {contradictionsWithSolvings[id].attributeDependency?.length
                                                ? 'Open idea'
                                                : 'Create idea'}
                                        </button>
                                    </Tooltip>

                                    {currentActiveCell[0] === index && currentActiveCell[1] === 0 && (
                                        <div
                                            className={`contradiction_table_dropdown__additional_style_container ${
                                                index === contradictions.length - 1 &&
                                                contradictions.length > 2 &&
                                                'contradiction_table_dropdown__additional_style_container_upper_cell'
                                            }`}
                                            style={
                                                index === contradictions.length - 1 && contradictions.length > 2
                                                    ? {
                                                          top: `-${
                                                              170 +
                                                              35 *
                                                                  contradictionsWithSolvings[id].attributeDependency
                                                                      .length -
                                                              (contradictionsWithSolvings[id].attributeDependency
                                                                  .length === 3
                                                                  ? 30
                                                                  : 0)
                                                          }px`,
                                                      }
                                                    : null
                                            }
                                        >
                                            <CellDropdown
                                                row={index}
                                                column={0}
                                                rows={contradictionsWithSolvings[id].attributeDependency}
                                                setSelectedCell={currentCellSetter}
                                                handleOpenDetails={handleOpenEditModal}
                                                isNewIdeaButtonHidden={
                                                    contradictionsWithSolvings[id].attributeDependency.length > 2
                                                }
                                            />
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <Tooltip
                                        message={
                                            !(currentActiveCell[0] === index && currentActiveCell[1] === 1) &&
                                            `There ${taskUnificationDependencyprefix} ${taskUnificationSolutionLength} ${taskUnificationDependencysolutionForm} for this contradiction by this method`
                                        }
                                        tooltipMessageClass='my-projects-tooltip'
                                        position='top'
                                    >
                                        <button
                                            className={'contradiction_idea_btn'}
                                            style={{
                                                backgroundColor: `${getCellColor(
                                                    contradictionsWithSolvings[id].taskUnification
                                                )}`,
                                            }}
                                            onClick={() => {
                                                setCurrentContradictionSolvingType('taskUnification');
                                                setCurrentActiveCell([index, 1]);
                                            }}
                                        >
                                            {contradictionsWithSolvings[id].taskUnification?.length
                                                ? 'Open idea'
                                                : 'Create idea'}
                                        </button>
                                    </Tooltip>

                                    {currentActiveCell[0] === index && currentActiveCell[1] === 1 && (
                                        <div
                                            className={`contradiction_table_dropdown__additional_style_container ${
                                                index === contradictions.length - 1 &&
                                                contradictions.length > 2 &&
                                                'contradiction_table_dropdown__additional_style_container_upper_cell'
                                            }`}
                                            style={
                                                index === contradictions.length - 1 && contradictions.length > 2
                                                    ? {
                                                          top: `-${
                                                              170 +
                                                              35 *
                                                                  contradictionsWithSolvings[id].taskUnification
                                                                      .length -
                                                              (contradictionsWithSolvings[id].taskUnification.length ===
                                                              3
                                                                  ? 30
                                                                  : 0)
                                                          }px`,
                                                      }
                                                    : null
                                            }
                                        >
                                            <CellDropdown
                                                row={index}
                                                column={1}
                                                rows={contradictionsWithSolvings[id].taskUnification}
                                                setSelectedCell={currentCellSetter}
                                                handleOpenDetails={handleOpenEditModal}
                                                isNewIdeaButtonHidden={
                                                    contradictionsWithSolvings[id].taskUnification.length > 2
                                                }
                                            />
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </>
                    );
                })}
            </table>
        </div>
    );
};

export default ContradictionsTable;
