import React, { useState, useEffect } from 'react';
import BlockDiagram from './diagrams/BlockDiagram';
import IdeasTab from './IdeasTab';

import { ReactComponent as LightbulbIconMedium } from '../../../assets/images/lightbulb-icon-big.svg';

import { getTranslation } from '../../../helpers/getLanguage';
import DonutIdeasDiagram from './diagrams/DonutIdeasDiagram';

const blockLabels = ['IDEAS_TAB_LABEL_IN_PROGRESS', 'IDEAS_TAB_LABEL_NOT_FEASIBLE', 'IDEAS_TAB_LABEL_IDEAS'];
const blockColors = ['#ffcd67', '#f9ae9f', '#5ac57a'];

const getIdeas = (detailsMatrix, subtractions, replacements, eurekaIdeas, multiplications, contradictionsSolvings=[], status) => {
    const ideas = [];
    detailsMatrix
        .filter((idea) => idea.status === status || (idea.reversed && idea.reversed.status === status))
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
            if (idea.reversed && idea.reversed.status === status) {
                ideas.push({ ...idea, isReversed: true });
            }
        });
    subtractions
        .filter((idea) => idea.status === status)
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
        });
    replacements
        .filter((idea) => idea.status === status)
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
        });
    multiplications
        .filter((idea) => idea.status === status)
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
        });
    eurekaIdeas
        .filter((idea) => idea.status === status)
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
        });
    contradictionsSolvings
        .filter((idea) => idea.status === status)
        .forEach((idea) => {
            idea.status === status && ideas.push(idea);
        });

    return ideas;
};

const getDividedIdeasCount = (
    subtractions,
    detailsMatrix,
    replacements,
    eurekaIdeas,
    multiplications,
    contradictionsSolvings=[]
) => {
    const eurekaIdeasList = [];
    const subtractionIdeas = [];
    const replacementsIdeas = [];
    const multiplicationIdeas = [];
    const detailsMatrixIdeas = [];
    const contradictionsSolvingIdeas = [];

    detailsMatrix
        .filter((idea) => idea.status > 0 || (idea.reversed && idea.reversed.status > 0))
        .forEach((idea) => {
            idea.status > 0 && detailsMatrixIdeas.push(idea);
            if (idea.reversed && idea.reversed.status > 0) {
                detailsMatrixIdeas.push({ ...idea, isReversed: true });
            }
        });
    subtractions
        .filter((idea) => idea.status > 0)
        .forEach((idea) => {
            idea.status > 0 && subtractionIdeas.push(idea);
        });
    replacements
        .filter((idea) => idea.status > 0)
        .forEach((idea) => {
            idea.status > 0 && replacementsIdeas.push(idea);
        });
    multiplications
        .filter((idea) => idea.status > 0)
        .forEach((idea) => {
            idea.status > 0 && multiplicationIdeas.push(idea);
        });
    eurekaIdeas
        .filter((idea) => idea.status > 0)
        .forEach((idea) => {
            idea.status > 0 && eurekaIdeasList.push(idea);
        });
    contradictionsSolvings.filter((idea) => idea.status > 0)
        .forEach((idea) => {
            idea.status > 0 && contradictionsSolvingIdeas.push(idea);
        });

    return {
        detailsMatrix: detailsMatrixIdeas.length || 0,
        subtractions: subtractionIdeas.length || 0,
        replacements: replacementsIdeas.length || 0,
        multiplications: multiplicationIdeas.length || 0,
        eurekaIdeas: eurekaIdeasList.length || 0,
        contradictionsSolvings: contradictionsSolvingIdeas.length || 0,
    };
};

const IdeasCard = ({
    detailsMatrix,
    subtractions,
    replacements,
    multiplications,
    eurekaIdeas,
    contradictions,
    contradictionsSolvings=[],
    components,
    attributes,
    projectId,
    history,
    navigateToReports,
    projectName,
    projectType,
}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [ideas, setIdeas] = useState({ feasible: [], inProgress: [] });
    const [diagramValues, setDiagramValues] = useState([]);
    const [ideasCount, setIdeasCount] = useState(
        getDividedIdeasCount(
            subtractions,
            detailsMatrix,
            replacements,
            eurekaIdeas,
            multiplications,
            contradictionsSolvings
        )
    );

    useEffect(() => {
        const inProgress = getIdeas(detailsMatrix, subtractions, replacements, eurekaIdeas, multiplications, contradictionsSolvings, 1);
        const notFeasible = getIdeas(detailsMatrix, subtractions, replacements, eurekaIdeas, multiplications, contradictionsSolvings, 2);
        const feasible = getIdeas(detailsMatrix, subtractions, replacements, eurekaIdeas, multiplications, contradictionsSolvings, 3);

        feasible.sort((a, b) => {
            const getRating = (idea) => (idea.isReversed ? idea.reversed.rating : idea.rating);
            return getRating(b) - getRating(a);
        });

        setIdeas({ feasible, inProgress });
        setIdeasCount(
            getDividedIdeasCount(
                subtractions,
                detailsMatrix,
                replacements,
                eurekaIdeas,
                multiplications,
                contradictionsSolvings
            )
        );
        setDiagramValues([inProgress.length, notFeasible.length, feasible.length]);
    }, [detailsMatrix, subtractions, replacements, eurekaIdeas, multiplications, contradictionsSolvings]);

    return (
        <div className='project-dashboard-card ideas flex'>
            {(detailsMatrix.length > 0 ||
                subtractions.length > 0 ||
                replacements.length > 0 ||
                multiplications.length > 0 ||
                eurekaIdeas.length > 0 ||
                contradictionsSolvings?.length > 0) && (
                <div className='project-card-content flex-column'>
                    <div className='flex justify-space-between'>
                        <div className='project-dashboard-card-title-container flex align-center'>
                            <LightbulbIconMedium style={{ color: '#8a93c3' }} />
                            <span className='project-dashboard-card-title'>
                                {getTranslation('DASHBOARD_CARD_TITLE_IDEAS')}
                            </span>
                        </div>

                        <button
                            className='dashboard-card-button medium ideas-button flex mt-20'
                            onClick={() => history.push(`/project/${projectId}/report`)}
                        >
                            <span>{getTranslation('DASHBOARD_CARD_BUTTON_VIEW_IDEAS')}</span>
                            <div className='button-triangle-icon'></div>
                        </button>
                    </div>

                    <div className='card-content-container height-100'>
                        <div className='dashboard-card-donut-diagram flex align-center justify-space-around'>
                            <DonutIdeasDiagram values={diagramValues} colors={blockColors} ideasCount={ideasCount} />
                            <div className='ideas-right-diagram'>
                                <BlockDiagram
                                    isProcessType={projectType === 'process'}
                                    values={diagramValues}
                                    labels={blockLabels}
                                    colors={blockColors}
                                />
                                <div className='ideas-count flex'>
                                    <span className='ad-ideas-count'>{`${getTranslation('IDEAS_TAB_AD')} ${
                                        ideasCount.detailsMatrix
                                    }`}</span>
                                    <span className='s-ideas-count'>{`${getTranslation('IDEAS_TAB_S')} ${
                                        ideasCount.subtractions
                                    }`}</span>
                                    <span className='r-ideas-count'>{`${getTranslation('IDEAS_TAB_R')} ${
                                        ideasCount.replacements
                                    }`}</span>
                                    <span className='r-ideas-count'>{`${getTranslation('IDEAS_TAB_EU')} ${
                                        ideasCount.eurekaIdeas
                                    }`}</span>
                                    <span className='r-ideas-count'>{`${getTranslation('IDEAS_TAB_M')} ${
                                        ideasCount.multiplications
                                    }`}</span>
                                    <span className='r-ideas-count'>{`${getTranslation('IDEAS_TAB_CA')} ${
                                        ideasCount.contradictionsSolvings
                                    }`}</span>
                                </div>
                            </div>
                        </div>

                        <div className='ideas-container flex'>
                            <div
                                className='ideas-tab-header flex flex-column align-center justify-space-between pointer'
                                onClick={() => setCurrentTab(0)}
                            >
                                <span className='ideas-tab-label'>
                                    {getTranslation('IDEAS_TAB_LABEL_TOP_RATED_IDEAS')}
                                </span>
                                {!currentTab && <div className='selection-line'></div>}
                            </div>

                            <div
                                className='ideas-tab-header flex flex-column align-center justify-space-between pointer'
                                onClick={() => setCurrentTab(1)}
                            >
                                <span className='ideas-tab-label'>{getTranslation('IDEAS_TAB_LABEL_IN_PROGRESS')}</span>
                                {currentTab === 1 && <div className='selection-line yellow'></div>}
                            </div>
                        </div>

                        <div className='ideas-content flex'>
                            <IdeasTab
                                projectName={projectName}
                                contradictions={contradictions}
                                ideas={currentTab === 0 ? ideas.feasible : ideas.inProgress}
                                components={components}
                                attributes={attributes}
                                navigateToReports={navigateToReports}
                                inProgress={currentTab > 0}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IdeasCard;
